<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15px"
    height="15px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-chevron-down open-indicator vs__open-indicator"
    role="presentation"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
</template>

<script>
export default {
  name: 'OpenIndicator',
}
</script>
